import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import CssBaseline from '@material-ui/core/CssBaseline'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Link from '@material-ui/core/Link'
import Image from 'material-ui-image'
import { Divider } from '@material-ui/core'
import Carousel from './Carousel'
import "./index.css"

const useStyles = makeStyles((theme) => ({
    icon: {
        marginRight: theme.spacing(2),
    },
    heroContent: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
        marginTop: theme.spacing(4),
    },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9
    },
    cardContent: {
        flexGrow: 1,
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2),
    },
}));

const sendEmail = (email, message) => {

    let formData = new FormData()
    formData.append("message", message)
    formData.append("email", email)
    fetch("/mail.php", {
        method: 'POST',
        body: formData
    })
}

export default function Album() {
    const classes = useStyles()
    const [message, setMessage] = useState("")
    const [email, setEmail] = useState("")
    const [isSended, setIsSended] = useState(false)


    return (
        <React.Fragment>
            <CssBaseline />
            <main>
                <div className={classes.heroContent}>
                    <Container maxWidth="md">
                        <Image src={"./cookizz_logo.png"} style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', width: '80%' }} aspectRatio={(16 / 3)} />

                        <Typography variant="h4" align="center" style={{ color: 'rgba(0, 0, 0, 0.67)' }} paragraph>
                            Une expérience interactive à travers votre smartphone
                        </Typography>
                        <Typography variant="subtitle1" style={{ fontSize: 20 }} align="center" color="textSecondary" paragraph>
                            Cookizz propose une animation événementielle utilisant les nouvelles technologies<br />
                            Nous fournissons aux joueurs des quiz interactifs <b>100% controlables</b> par smartphone
                        </Typography>
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 35 }} >
                            <img src={"/assets/t4.svg"} style={{ height: 210 }} />
                        </div>
                    </Container>
                </div>
                <Container maxWidth="lg" style={{ padding: 20 }}>
                    <Typography variant="h4" align="center" component="p" style={{ color: 'rgba(0, 0, 0, 0.67)' }}>
                        Nos sessions
                    </Typography>
                    <Typography variant="h5" align="center" color="textSecondary" component="p">
                        Les prochaines parties de Cookizz
                    </Typography>
                    <Grid spacing={2} container style={{ marginTop: 20, marginBottom: 20 }}>
                        <Grid item xs={12} sm={6} md={6} lg={4}>
                            <Card elevation={0} style={{ width: "100%", cursor: 'pointer', }} onClick={() => { window.open("https://manga-mania.fr", '_blank').focus(); }}>
                                <Grid container xs={12} style={{ justifyContent: 'center' }} >
                                    <img style={{ height: 150, marginTop: 10, marginBottom: 10, borderRadius: 2, filter: 'blur(1px)' }} src={"/assets/manga.jpg"} />
                                    <Grid item xs={12} justify='center' style={{ verticalAlign: 'center', alignContent: 'center', alignSelf: 'center' }}>
                                        <Grid item xs={12} justify='center'>
                                            <Typography variant="h5" align="center" color="textSecondary" style={{ verticalAlign: 'center', alignContent: 'center', alignSelf: 'center' }}>
                                                12h - Grande scène 
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} justify='center'>
                                            <Typography variant="subtitle1" align="center" color="textSecondary" style={{ verticalAlign: 'center', alignContent: 'center', alignSelf: 'center' }}>
                                                Manga Mania - 4 Juin 2022
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} justify='center' style={{ backgroundColor: '#e5b075', marginTop: 12, marginLeft: 40, marginRight: 40, borderRadius: 3 }}>
                                            <Typography paragraph variant="subtitle1" align="center" style={{ color: 'white', verticalAlign: 'center', alignContent: 'center', alignSelf: 'center' }}>
                                                Thème : Mangas et Animes
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4}>
                            <Card elevation={0} style={{ width: "100%", cursor: 'pointer', }} onClick={() => { window.open("https://manga-mania.fr", '_blank').focus(); }}>
                                <Grid container xs={12} style={{ justifyContent: 'center' }} >
                                    <img style={{ height: 150, marginTop: 10, marginBottom: 10, borderRadius: 2 }} src={"/assets/t6.svg"} />
                                    <Grid item xs={12} justify='center' style={{ verticalAlign: 'center', alignContent: 'center', alignSelf: 'center' }}>
                                        <Grid item xs={12} justify='center'>
                                            <Typography variant="h5" align="center" color="textSecondary" style={{ verticalAlign: 'center', alignContent: 'center', alignSelf: 'center' }}>
                                                13h00 - Podium extérieur
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} justify='center'>
                                            <Typography variant="subtitle1" align="center" color="textSecondary" style={{ verticalAlign: 'center', alignContent: 'center', alignSelf: 'center' }}>
                                                Manga Mania - 4 Juin 2022
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} justify='center' style={{ backgroundColor: '#e5b075', marginTop: 12, marginLeft: 40, marginRight: 40, borderRadius: 3 }}>
                                            <Typography paragraph variant="subtitle1" align="center" style={{ color: 'white', verticalAlign: 'center', alignContent: 'center', alignSelf: 'center' }}>
                                                Thème : Encore secret
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4}>
                            <Card elevation={0} style={{ width: "100%", cursor: 'pointer', }} onClick={() => { window.open("https://manga-mania.fr", '_blank').focus(); }}>
                                <Grid container xs={12} style={{ justifyContent: 'center' }} >
                                    <img style={{ height: 150, marginTop: 10, marginBottom: 10, borderRadius: 2 }} src={"/assets/t6.svg"} />
                                    <Grid item xs={12} justify='center' style={{ verticalAlign: 'center', alignContent: 'center', alignSelf: 'center' }}>
                                        <Grid item xs={12} justify='center'>
                                            <Typography variant="h5" align="center" color="textSecondary" style={{ verticalAlign: 'center', alignContent: 'center', alignSelf: 'center' }}>
                                                13h30 - Podium extérieur
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} justify='center'>
                                            <Typography variant="subtitle1" align="center" color="textSecondary" style={{ verticalAlign: 'center', alignContent: 'center', alignSelf: 'center' }}>
                                                Manga Mania - 4 Juin 2022
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} justify='center' style={{ backgroundColor: '#e5b075', marginTop: 12, marginLeft: 40, marginRight: 40, borderRadius: 3 }}>
                                            <Typography paragraph variant="subtitle1" align="center" style={{ color: 'white', verticalAlign: 'center', alignContent: 'center', alignSelf: 'center' }}>
                                                Thème : Encore secret
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4}>
                            <Card elevation={0} style={{ width: "100%", cursor: 'pointer', }} onClick={() => { window.open("https://manga-mania.fr", '_blank').focus(); }}>
                                <Grid container xs={12} style={{ justifyContent: 'center' }} >
                                    <img style={{ height: 150, marginTop: 10, marginBottom: 10, borderRadius: 2, filter: 'blur(1px)' }} src={"/assets/film.jpg"} />
                                    <Grid item xs={12} justify='center' style={{ verticalAlign: 'center', alignContent: 'center', alignSelf: 'center' }}>
                                        <Grid item xs={12} justify='center'>
                                            <Typography variant="h5" align="center" color="textSecondary" style={{ verticalAlign: 'center', alignContent: 'center', alignSelf: 'center' }}>
                                                12h00 - Petite scène
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} justify='center'>
                                            <Typography variant="subtitle1" align="center" color="textSecondary" style={{ verticalAlign: 'center', alignContent: 'center', alignSelf: 'center' }}>
                                                Manga Mania - 5 Juin 2022
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} justify='center' style={{ backgroundColor: '#e5b075', marginTop: 12, marginLeft: 40, marginRight: 40, borderRadius: 3 }}>
                                            <Typography paragraph variant="subtitle1" align="center" style={{ color: 'white', verticalAlign: 'center', alignContent: 'center', alignSelf: 'center' }}>
                                                Thème : Films et séries
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4}>
                            <Card elevation={0} style={{ width: "100%", cursor: 'pointer', }} onClick={() => { window.open("https://manga-mania.fr", '_blank').focus(); }}>
                                <Grid container xs={12} style={{ justifyContent: 'center' }} >
                                    <img style={{ height: 150, marginTop: 10, marginBottom: 10, borderRadius: 2, filter: 'blur(1px)' }} src={"/assets/jeuxvidéo.jpg"} />
                                    <Grid item xs={12} justify='center' style={{ verticalAlign: 'center', alignContent: 'center', alignSelf: 'center' }}>
                                        <Grid item xs={12} justify='center'>
                                            <Typography variant="h5" align="center" color="textSecondary" style={{ verticalAlign: 'center', alignContent: 'center', alignSelf: 'center' }}>
                                                13h - Grande scène
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} justify='center'>
                                            <Typography variant="subtitle1" align="center" color="textSecondary" style={{ verticalAlign: 'center', alignContent: 'center', alignSelf: 'center' }}>
                                                Manga Mania - 6 Juin 2022
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} justify='center' style={{ backgroundColor: '#e5b075', marginTop: 12, marginLeft: 40, marginRight: 40, borderRadius: 3 }}>
                                            <Typography paragraph variant="subtitle1" align="center" style={{ color: 'white', verticalAlign: 'center', alignContent: 'center', alignSelf: 'center' }}>
                                                Thème : Jeux vidéo
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
                <div style={{ background: 'white' }}>
                    <Container maxWidth="lg" style={{ paddingTop: 30, paddingBottom: 30 }}>
                        <Typography variant="h4" align="center" component="p" style={{ color: 'rgba(0, 0, 0, 0.67)' }}>
                            Concept simple et ludique
                        </Typography>
                        <Typography variant="h5" align="center" color="textSecondary" component="p">
                            Les fondamentaux de Cookizz
                        </Typography>
                        <Grid container spacing={2} xs={12} style={{ marginTop: 24 }} >
                            <Grid item xs={12} sm={6} md={6} lg={4} justify='center' direction='column' style={{ marginTop: 10, marginBottom: 10, paddingRight: 10, paddingBottom: 10 }}>

                                <div style={{ display: 'flex', justifyContent: 'center' }} >
                                    <img src={"/assets/t1.svg"} style={{ height: 200 }} />
                                </div><Typography variant="h4" align="center" color="textSecondary" style={{ marginTop: 20, marginBottom: 12 }}>
                                    Des quiz interactifs
                                </Typography>
                                <Divider style={{ marginBottom: 12, marginLeft: 50, marginRight: 50 }} />
                                <Typography style={{ fontSize: 17 }} align="center" color="textSecondary" >
                                    Cookizz permet à des joueurs de s'affronter en même temps sur des quiz fait sur mesure pour vos événements
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={4} justify='center' direction='column' style={{ marginTop: 10, marginBottom: 10, paddingRight: 10, paddingBottom: 10 }}>
                                <div style={{ display: 'flex', justifyContent: 'center' }} >
                                    <img src={"/assets/t2.svg"} style={{ height: 200 }} />
                                </div>
                                <Typography variant="h4" align="center" color="textSecondary" style={{ marginTop: 20, marginBottom: 12 }}>
                                    Un jeu tout public
                                </Typography>
                                <Divider style={{ marginBottom: 12, marginLeft: 50, marginRight: 50 }} />
                                <Typography style={{ fontSize: 17 }} align="center" color="textSecondary" >
                                    Toute personne possédant un smartphone peut rejoindre une partie de Cookizz et participer au classement général
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={4} justify='center' direction='column' style={{ marginTop: 10, marginBottom: 10, paddingRight: 10, paddingBottom: 10 }}>
                                <div style={{ display: 'flex', justifyContent: 'center' }} >
                                    <img src={"/assets/t3.svg"} style={{ height: 200 }} />
                                </div><Typography variant="h4" align="center" color="textSecondary" style={{ marginTop: 20, marginBottom: 12 }}>
                                    Jouer sans condition
                                </Typography>
                                <Divider style={{ marginBottom: 12, marginLeft: 50, marginRight: 50 }} />
                                <Typography style={{ fontSize: 17 }} align="center" color="textSecondary">
                                    Cookizz fonctionne <b>sans internet</b> et peut faire participer des centaines de joueurs en simultanés
                                </Typography>
                            </Grid>
                            <Grid container xs={12} justify='center' direction='column'>

                            </Grid>
                        </Grid>
                    </Container>
                </div>
                <Container maxWidth="md" style={{ padding: 20 }}>
                    <Typography variant="h4" align="center" component="p" style={{ color: 'rgba(0, 0, 0, 0.67)' }}>
                        Gallerie Cookizz
                    </Typography>
                    <Typography variant="h5" align="center" color="textSecondary" component="p">
                        Affichage en grand écran et sur mobile
                    </Typography>

                    <Carousel images={[
                        { id: 1, url: "/assets/1.png" },
                        { id: 2, url: "/assets/2.png" },
                        { id: 3, url: "/assets/3.png" },
                        { id: 4, url: "/assets/4.png" },
                        { id: 5, url: "/assets/5.png" },
                        { id: 6, url: "/assets/6.png" },
                        { id: 7, url: "/assets/7.png" },
                        { id: 8, url: "/assets/8.png" },
                        { id: 9, url: "/assets/9.png" },
                        { id: 10, url: "/assets/10.png" },
                    ]} width={'100%'} showThumbs={false} >
                    </Carousel>
                </Container>
                <div style={{ background: 'white' }}>
                    <Container maxWidth="md" style={{ paddingTop: 30, paddingBottom: 30 }}>
                        <Typography variant="h4" align="center" component="p" style={{ color: 'rgba(0, 0, 0, 0.67)' }}>
                            Contact
                        </Typography>
                        <Typography variant="h5" align="center" color="textSecondary" component="p">
                            Vous voulez Cookizz dans votre événement ou des informations supplémentaires ?
                        </Typography>
                        <Box noValidate sx={{ mt: 3 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        onChange={(event) => { setEmail(event.target.value) }}
                                        fullWidth
                                        value={email}
                                        id="email"
                                        label="Email"
                                        name="email"
                                        autoComplete="email"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        onChange={(event) => { setMessage(event.target.value) }}
                                        fullWidth
                                        value={message}
                                        multiline={4}
                                        name="message"
                                        label="Message"
                                        id="message"
                                    />
                                </Grid>
                            </Grid>
                            <Button
                                onClick={() => { sendEmail(email, message); setIsSended(true); setMessage(""); setEmail("") }}
                                type="submit"
                                color='primary'
                                variant="contained"
                                style={{ color: 'white', marginTop: 25 }}
                            >
                                Envoyer
                            </Button>
                        </Box>
                        {
                            isSended ?
                                <Box style={{ width: '250px', marginTop: 10, backgroundColor: '#00873D', padding: 5, borderRadius: 5, justifyContent: 'center', alignContent: 'center', alignSelf: 'center', alignItems: 'center', display: 'flex' }}>
                                    <Typography variant="subtitle1" align="center" style={{ color: 'white' }} component="p">
                                        Message envoyé
                                    </Typography>
                                </Box> : null
                        }
                    </Container>
                </div>
            </main>
            <footer className={classes.footer}>
                <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
                    Une expérience interactive à travers votre smartphone
                </Typography>
                <Typography variant="body2" color="textSecondary" align="center">
                    {'Copyright © '}
                    <Link color="inherit" href="https://cookizz.fr/"> Cookizz.fr </Link>
                    {' ' + new Date().getFullYear()}
                </Typography>
            </footer>
        </React.Fragment>
    );
}