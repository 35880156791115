import React from 'react'
import PreHome from './PreHome'
import { MuiThemeProvider, createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles'

let theme = createMuiTheme({
  palette: {
    primary: {
      main: "#e5b075"
    },
    secondary: {
      main: '#7c3f3b',
    },
  },
})



class App extends React.Component {


  constructor(props) {
    theme = responsiveFontSizes(theme)
    super(props)
    this.state = {
      prehome: true
    }
  }

  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <PreHome />
      </MuiThemeProvider>
    )
  }

}

export default App;
